jQuery(document).ready(function($) {
    
    // $(document).on('facetwp-loaded', function() {
    //     if (FWP.loaded) {
    //         $('html, body').animate({
    //             scrollTop: $('.facetwp-template').offset().top
    //         }, 1000);
    //     }
    // });


})